import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { SvgIconComponent } from 'angular-svg-icon';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'tfgh-toast-view',
  standalone: true,
  imports: [ToastModule, SvgIconComponent],
  templateUrl: './toast.view.html',
  styleUrl: './toast.view.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastViewComponent {
  protected _messageService = inject(MessageService)

  toastNoti(
    type: 'error' | 'success' | 'warning' | 'info',
    messageDetail?: string,
    title = '',
  ) {
    let _messageTitle = '';
    switch (type) {
      case 'success':
        _messageTitle = 'Successful';
        break;
      case 'error':
        _messageTitle = 'Error';
        break;
      case 'warning':
        _messageTitle = 'Warning';
        break;
      case 'info':
        _messageTitle = 'Info';
        break;
    }
    this._messageService.add({
      severity: type,
      summary: title || _messageTitle,
      detail: messageDetail ?? 'Unknown error',
    });
  }
}
