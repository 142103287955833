<p-toast>
  <ng-template let-msg pTemplate="headless">
    <div class="flex gap-3 px-4 py-3">
      <div>
        @switch (msg.severity) { @case ('success') {
        <svg-icon class="text-white" src="icons/tick-circle.svg"></svg-icon>
        } @case ('error') {
        <svg-icon class="text-white" src="icons/error-circle.svg"></svg-icon>
        } @case ('warn') {
        <svg-icon class="text-white" src="icons/warning.svg"></svg-icon>
        } @case ('info') {
        <svg-icon class="text-white" src="icons/info-circle.svg"></svg-icon>
        } @default {
        <svg-icon class="text-white" src="icons/tick-circle.svg"></svg-icon>
        } }
      </div>
      <div>
        <h6 class="text-base font-semibold">{{ msg.summary }}</h6>
        <div class="text-sm mt-1" [innerHTML]="msg.detail"></div>
      </div>
    </div>
  </ng-template>
</p-toast>
